// External library imports
import axios from 'axios';

// Vue imports
import { ref, watch } from 'vue';

// Router import
import router from '../router'; // Adjust the path as needed

// Constants
const API_URL = 'https://terracrawler.com/api/auth/';
const SIMULATOR_URL = 'https://sim.terracrawler.com/auth';

// Reactive state
const currentUser = ref(JSON.parse(localStorage.getItem('user')));

watch(currentUser, (newUser) => {
    if (newUser) {
        localStorage.setItem('user', JSON.stringify(newUser));
    } else {
        localStorage.removeItem('user');
    }
}, {
    deep: true
});

// Create a function to get the latest token
const getLatestToken = () => currentUser.value ? currentUser.value.token : null;

let isLoggingOut = false;

const handleGlobalError = async (error) => {
    console.error('Global error:', error);
    if (!isLoggingOut && (error.message.includes('AMQJS0008I Socket closed') || 
        error.message.includes('Connection lost') ||
        (error.response && error.response.status === 401))) {
        console.log('Authentication error detected. Logging out...');
        await authService.logout(true);
    }
};

// Update the axios interceptor to use the getLatestToken function
axios.interceptors.request.use(
    config => {
        const token = getLatestToken();
        if (token) {
            console.log("Setting Authorization header with token:", token);
            config.headers['Authorization'] = 'Bearer ' + token;
        } else {
            console.warn("No token available for request");
            currentUser.value = null;
            localStorage.removeItem('user');
            window.dispatchEvent(new CustomEvent('user-logout', {
                detail: {
                    success: false
                }
            }));
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

// Add this axios response interceptor after the request interceptor
axios.interceptors.response.use(
    response => response,
    async error => {
        await handleGlobalError(error);
        return Promise.reject(error);
    }
);

class AuthService {
    constructor() {
        this.currentUser = ref(null);
    }

    async login(username, password) {
        try {
            const response = await axios.post(API_URL + 'login', {
                username,
                password
            });
            if (response.data.token && response.data.user) {
                const userData = {
                    _id: response.data.user._id,
                    username: response.data.user.username,
                    role: response.data.user.role,
                    token: response.data.token
                };
                localStorage.setItem('user', JSON.stringify(userData));
                currentUser.value = userData;
                window.dispatchEvent(new Event('user-login'));
            }
            return response.data;
        } catch (error) {
            console.error('Login failed:', error);
            throw error;
        }
    }

    async logout(isForced = false) {
        if (isLoggingOut) return;
        isLoggingOut = true;
        
        try {
            const token = this.getToken();
            if (token && !isForced) {
                try {
                    await axios.post(API_URL + 'logout', {}, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });
                } catch (error) {
                    console.warn('Logout request failed, but continuing with local logout');
                }
            }
        } finally {
            currentUser.value = null;
            localStorage.removeItem('user');
            window.dispatchEvent(new CustomEvent('user-logout', {
                detail: { success: true }
            }));
            isLoggingOut = false;
            router.push('/login');
        }
    }

    register(username, email, password) {
        return axios.post(API_URL + 'register', {
            username,
            email,
            password
        });
    }

    async getCurrentUser() {
        const userStr = localStorage.getItem('user');
        console.log("User string from localStorage:", userStr);
        if (userStr) {
            const user = JSON.parse(userStr);
            console.log("Parsed user object:", user);
            if (!user._id) {
                console.warn("User ID is missing from the stored user object. Attempting to refresh...");
                return await this.refreshUserData();
            }
            return user;
        }
        console.log("No user found in localStorage");
        return null;
    }

    getUserId() {
        const user = this.getCurrentUser();
        return user ? user._id : null;
    }

    getToken() {
        return getLatestToken();
    }

    updateToken(newToken) {
        if (currentUser.value) {
            currentUser.value.token = newToken;
        }
    }

    initiateMQTTConnection() {
        window.dispatchEvent(new CustomEvent('initiate-mqtt-connection'));
    }

    async authenticateSimulator() {
        const token = this.getToken();
        if (!token) {
            console.error('No token available. User might not be logged in.');
            return false;
        }
        try {
            const response = await axios.post(SIMULATOR_URL, {
                token
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Cache-Control': 'no-cache',
                    'Pragma': 'no-cache'
                }
            });
            return response.data.success;
        } catch (error) {
            console.error('Failed to authenticate simulator:', error);
            return false;
        }
    }

    isAuthenticated() {
        return !!this.currentUser.value;
    }

    async refreshUserData() {
        const token = this.getToken();
        if (!token) {
            console.error('No token available. User might not be logged in.');
            await this.logout();
            return null;
        }
        try {
            const response = await axios.get(API_URL + 'user', {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            if (response.data.user) {
                const userData = {
                    _id: response.data.user._id,
                    username: response.data.user.username,
                    role: response.data.user.role,
                    token: token
                };
                localStorage.setItem('user', JSON.stringify(userData));
                currentUser.value = userData;
                return userData;
            }
        } catch (error) {
            console.error('Failed to refresh user data:', error);
            await this.logout();
            return null;
        }
    }
}

const authService = new AuthService();
export default authService;

// Export the handleGlobalError function
export { handleGlobalError };