import { createRouter, createWebHistory } from 'vue-router';
import AuthService from '@/services/AuthService';

// Lazy load components
const Home = () => import(/* webpackChunkName: "home" */ '@/views/HomePage.vue');
const Login = () => import(/* webpackChunkName: "auth" */ '@/components/LoginForm.vue');
const AdminPage = () => import(/* webpackChunkName: "admin" */ '@/views/AdminPage.vue');

const routes = [{
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminPage,
    meta: {
      requiresAdmin: true
    }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const user = await AuthService.getCurrentUser();
  const adminRequired = to.matched.some(record => record.meta.requiresAdmin);

  // Handle unauthenticated users
  if (authRequired && !user) {
    next('/login');
    return;
  }

  // Handle non-admin users trying to access admin pages
  if (adminRequired && (!user || user.role !== 'admin')) {
    next('/');
    return;
  }

  // Handle already logged-in users trying to access login page
  if (to.path === '/login' && user) {
    next('/');
    return;
  }

  next();
});

export default router;