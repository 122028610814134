import {
    createApp
} from 'vue';
import App from './App.vue';
import router from './router';
import './assets/styles.css';
// import {
//     initializeTensorFlow,
//     getTF
// } from '@/services/TensorFlowService';

async function init() {
    try {
        //await initializeTensorFlow();
        //const tf = getTF();

        // Test TensorFlow.js
        // console.log('TensorFlow.js is ready');
        // tf.tensor([1, 2, 3]).print();

        const app = createApp(App);
        app.use(router);
        app.mount('#app');
    } catch (error) {
        console.error("Failed to initialize the application:", error);
    }
}

init();