// src/services/LoggerService.js

class LoggerService {
  constructor() {
    this.isProduction = process.env.NODE_ENV === 'production';
  }

  log(...args) {
    if (!this.isProduction) {
      console.log(...args);
    }
  }

  warn(...args) {
    if (!this.isProduction) {
      console.warn(...args);
    }
  }

  error(...args) {
    // Always log errors, even in production
    console.error(...args);
  }

  info(...args) {
    if (!this.isProduction) {
      console.info(...args);
    }
  }

  debug(...args) {
    if (!this.isProduction) {
      console.debug(...args);
    }
  }
}

export default new LoggerService();
